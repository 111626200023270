const formActions = {
  CREATE_FORM: "CREATE_FORM",
  UPDATE_FORM: "UPDATE_FORM",
  GET_FORM: "GET_FORM",
  GET_SUMMARY: "GET_SUMMARY",
  GET_FORM_LIST: "GET_FORM_LIST",
  EDIT_FORM: "EDIT_FORM",
  DELETE_FORM: "DELETE_FORM",
  FORM_TOGGLE_HANDLE_MODAL: "FORM_TOGGLE_HANDLE_MODAL",
  CHANGE_FORM_PAGINATION: "CHANGE_FORM_PAGINATION",
  CHANGE_FORM_COUNT: "CHANGE_FORM_COUNT",
  createForm: (form) => {
    return (dispatch, getState) => {
      dispatch({
        type: formActions.CREATE_FORM,
        forms: [form, ...getState().form.forms],
      });
    };
  },
  updateForm: (form) => {
    return (dispatch, getState) => {
      dispatch({
        type: formActions.UPDATE_FORM,
        form,
      });
    };
  },
  editForm: (form) => {
    return (dispatch, getState) => {
      dispatch({
        type: formActions.EDIT_FORM,
        form,
      });
    };
  },
  getForms: (forms, formsCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: formActions.GET_FORM_LIST,
        forms,
        formsCount,
      });
    };
  },
  getSummary: (count, time) => {
    return (dispatch, getState) => {
      dispatch({
        type: formActions.GET_FORM_LIST,
        count,
        time,
      });
    };
  },
  deleteForm: (id) => {
    return (dispatch, getState) => {
      var currentForms = getState().form.forms.filter((s) => s.id !== id);
      dispatch({
        type: formActions.DELETE_FORM,
        forms: currentForms,
      });
    };
  },
  toggleModal: (form = null) => ({
    type: formActions.FORM_TOGGLE_HANDLE_MODAL,
    form,
  }),
  changePagination: (pagination) => {
    return (dispatch, getState) => {
      dispatch({
        type: formActions.CHANGE_FORM_PAGINATION,
        pagination,
      });
    };
  },
  updateFormsCount: (formsCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: formActions.CHANGE_FORM_COUNT,
        formsCount,
      });
    };
  },
};
export default formActions;
