import roleActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  roles: [],
  rolesCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: '',
    sort: ''
  },
  role: {
  }
};

export default function roleReducer(state = initState, action) {
  switch (action.type) {
    case roleActions.CREATE_ROLE:
      initState.role = {}
      return {
        ...state,
        roles: action.roles,
        role: initState.role
      };
    case roleActions.UPDATE_ROLE:
      var roleIndex = state.roles.findIndex(u => u.id === action.role.id);
      var roles = state.roles;
      roles[roleIndex] = action.role;
      return {
        ...state,
        roles:[...roles]
      };
    case roleActions.EDIT_ROLE:
      return {
        ...state,
        role: action.role,
      };
    case roleActions.GET_ROLE_LIST:
      return {
        ...state,
        roles: action.roles,
        rolesCount: action.rolesCount,
      };
    case roleActions.DELETE_ROLE:
      return {
        ...state,
        roles: action.roles,
      };
    case roleActions.ROLE_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        role: action.role === null ? initState.role : action.role,
      };
    case roleActions.CHANGE_ROLE_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case roleActions.CHANGE_ROLE_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}
