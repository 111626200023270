export default {
  fetchUrl: process.env.REACT_APP_AUTH_API,
  fileUrl: process.env.REACT_APP_STORAGE_API,
  checkPointUrl: process.env.REACT_APP_CHECKPOINT_API,
  alestaUrl: process.env.REACT_APP_ALESTA_API,
  feederUrl: process.env.REACT_APP_FEEDER_API,
  trapUrl: process.env.REACT_APP_TRAP_API,
  loggingUrl: process.env.REACT_APP_LOGGING_API,
  // gatewayUrl: process.env.REACT_APP_GATEWAYURL,
  // fetchUrl: 'http://localhost:10001/api',
  // fileUrl: 'http://localhost:10001',
  //fetchUrl: 'http://localhost:5000/api',
  //fetchUrl: 'https://gateway.codebefore.com/v1/authorization',
};

